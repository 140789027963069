import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import {
  DropdownMenu,
  DropdownMenuItem,
  DropdownMenuRoot,
  DropdownMenuTrigger,
} from './DropdownMenu';

export const Header: FC = () => {
  return (
    <header className="header">
      <div className="header__container">
        <div className="header__logo">
          <Link to="/">
            <img
              src="/img/logo.svg"
              width={152}
              height={33}
              alt="Logo"
              aria-hidden="true"
              className="header__img"
            />
          </Link>
        </div>
        <div className="header__info">
          <a
            href="https://portal.mendfamily.com/signup/new-patient/1932"
            className="header__login"
            target="_blank"
            rel="noreferrer"
          >
            Existing Patient Login
          </a>
          <a href="tel:1-866-478-3978" className="header__phone">
            <svg width="20" height="20" focusable="false" aria-hidden="true">
              <use xlinkHref="/icons.svg#phone" />
            </svg>
            1-866-478-3978
          </a>
          <Link to="/match" className="header__match">
            Let Us Match You
          </Link>
          <DropdownMenuRoot modal={false}>
            <DropdownMenuTrigger asChild>
              <button
                type="button"
                className="header__options"
                aria-label="More options"
              >
                <svg
                  width="20"
                  height="20"
                  focusable="false"
                  aria-hidden="true"
                >
                  <use xlinkHref="/icons.svg#3dots" />
                </svg>
              </button>
            </DropdownMenuTrigger>
            <DropdownMenu
              className="dropDown__content"
              sideOffset={12}
              align="end"
            >
              <DropdownMenuItem className="dropDown__item" asChild>
                <a
                  href="https://portal.mendfamily.com/signup/new-patient/1932"
                  className="header__login"
                  target="_blank"
                  rel="noreferrer"
                  style={{ textDecoration: 'none' }}
                >
                  <svg
                    width="16"
                    height="16"
                    focusable="false"
                    aria-hidden="true"
                  >
                    <use xlinkHref="/icons.svg#user" />
                  </svg>
                  Existing Patient Login
                </a>
              </DropdownMenuItem>
              <DropdownMenuItem className="dropDown__item" asChild>
                <a href="tel:1-866-478-3978" className="header__phone">
                  <svg
                    width="14"
                    height="14"
                    focusable="false"
                    aria-hidden="true"
                  >
                    <use xlinkHref="/icons.svg#phone" />
                  </svg>
                  1-866-478-3978
                </a>
              </DropdownMenuItem>
            </DropdownMenu>
          </DropdownMenuRoot>
        </div>
      </div>
    </header>
  );
};
