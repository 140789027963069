import React, {
  forwardRef,
  DetailedHTMLProps,
  InputHTMLAttributes,
} from 'react';

export type InputProps = {
  name: string;
  className?: string;
} & Omit<
  DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
  'size' | 'ref'
>;

type Ref = HTMLInputElement;

export const Input = forwardRef<Ref, InputProps>(
  ({ name, className, ...props }, forwardedRef) => {
    return (
      <input
        {...props}
        name={name}
        ref={forwardedRef}
        className={`input ${className || ''}`}
      />
    );
  },
);

Input.displayName = 'Input';
