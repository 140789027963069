import React, { FC } from 'react';
import { Link } from 'react-router-dom';

export const Empty: FC = () => {
  return (
    <div className="home__empty">
      <p className="home__subText">
        Oops! The combination of selections you chose is not finding a therapist
        to meet your needs. But one of our care specialists can help you if you
        click the button below and enter your information.
      </p>
      <div className="home__links">
        <Link
          to="/match"
          className="header__match"
          style={{ display: 'block' }}
        >
          Let Us Match You
        </Link>
        <a href="tel:1-866-478-3978" className="header__phone">
          <svg width="20" height="20" focusable="false" aria-hidden="true">
            <use xlinkHref="/icons.svg#phone" />
          </svg>
          1-866-478-3978
        </a>
      </div>
      <p className="home__subText">
        Remember if you are in a mental health emergency, please call{' '}
        <span className="home__subText--bold">988</span> or go to your nearest
        emergency department.
      </p>
    </div>
  );
};
