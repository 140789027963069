import React, { ReactNode, forwardRef } from 'react';
import { FieldError } from 'react-hook-form';
import * as SelectPrimitive from '@radix-ui/react-select';

interface SelectProps extends SelectPrimitive.SelectProps {
  children: ReactNode;
  id: string;
  error?: FieldError;
  hideError?: boolean;
  placeholder?: string;
  triggerClassName?: string;
  useTriggetWidth?: boolean;
  customValues?: Record<string, string>;
}

type SelectRef = HTMLButtonElement;

export const Select = forwardRef<SelectRef, SelectProps>(
  (
    {
      children,
      id,
      error,
      hideError = false,
      placeholder,
      useTriggetWidth = true,
      triggerClassName,
      customValues,
      ...props
    },
    forwardedRef,
  ) => {
    const hasError = !!(error && error.message);

    return (
      <SelectPrimitive.Root {...props}>
        <SelectPrimitive.Trigger
          ref={forwardedRef}
          className={`select__trigger 
            ${triggerClassName ? `${triggerClassName}` : ''} 
            ${props.value ? 'select__trigger--active' : ''} 
            ${hasError ? 'select__trigger--error' : ''}
          `}
          id={id}
        >
          {customValues ? (
            <SelectPrimitive.Value>
              {props.value ? customValues[props.value] : placeholder}
            </SelectPrimitive.Value>
          ) : (
            <SelectPrimitive.Value placeholder={placeholder} />
          )}
          <SelectPrimitive.Icon className="select__icon">
            <svg width="12" height="12" focusable="false" aria-hidden="true">
              <use xlinkHref="/icons.svg#arrow-down" />
            </svg>
          </SelectPrimitive.Icon>
          {hasError && (
            <span
              className={`label__error ${hideError ? 'srOnly' : ''}`}
              role="alert"
            >
              {error.message}
            </span>
          )}
        </SelectPrimitive.Trigger>
        <SelectPrimitive.Portal>
          <SelectPrimitive.Content
            className={`select__content ${
              useTriggetWidth ? 'select__content--triggerWidth' : ''
            }`}
            position="popper"
            sideOffset={8}
          >
            <div className="select__viewportWrapper">
              <SelectPrimitive.Viewport className="select__viewport">
                {children}
              </SelectPrimitive.Viewport>
            </div>
          </SelectPrimitive.Content>
        </SelectPrimitive.Portal>
      </SelectPrimitive.Root>
    );
  },
);

Select.displayName = 'Select';

interface SelectItemProps extends SelectPrimitive.SelectItemProps {
  children: ReactNode;
}

type SelectItemRef = HTMLDivElement;

export const SelectItem = forwardRef<SelectItemRef, SelectItemProps>(
  ({ children, ...props }, forwardedRef) => {
    return (
      <SelectPrimitive.Item
        {...props}
        ref={forwardedRef}
        className="select__item"
      >
        <SelectPrimitive.ItemText>{children}</SelectPrimitive.ItemText>
      </SelectPrimitive.Item>
    );
  },
);

SelectItem.displayName = 'SelectItem';
