import React from 'react';
import ReactDOM from 'react-dom/client';

import 'react-day-picker/dist/style.css';
import './assets/css/index.css';

import App from './App';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
