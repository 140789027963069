/* eslint-disable indent */
import React, { Dispatch, FC, SetStateAction, useRef } from 'react';

import { Select, SelectItem } from '../../../components/Select';
import { useFilters } from '../api/queries/useFilters';
import { useInsurance } from '../api/queries/useInsurance';

type FirstFiltersProps = {
  state: string;
  setState: Dispatch<SetStateAction<string>>;
  insurance: string;
  setInsurance: Dispatch<SetStateAction<string>>;
  areaOfFocus: string;
  setAreaOfFocus: Dispatch<SetStateAction<string>>;
  ageCategory: string;
  setAgeCategory: Dispatch<SetStateAction<string>>;
};

export const FirstFilters: FC<FirstFiltersProps> = ({
  state,
  setState,
  insurance,
  setInsurance,
  areaOfFocus,
  setAreaOfFocus,
  ageCategory,
  setAgeCategory,
}) => {
  const {
    isSuccess: isFiltersSuccess,
    isError: isFiltersError,
    error: filtersError,
    data: filters,
  } = useFilters();

  const {
    isSuccess: isCurrentInsuranceSuccess,
    isError: isCurrentInsuranceError,
    error: currentInsuranceError,
    data: currentInsurance,
  } = useInsurance({ state });

  const statesOptions =
    (isFiltersSuccess &&
      filters.data.states.map((state) => ({
        label: state.displayName,
        value: state.tag,
      }))) ||
    [];

  const insurancesOptions =
    isCurrentInsuranceSuccess && currentInsurance.data.length && state
      ? currentInsurance.data.reduce<Record<string, string>>((prev, curr) => {
          prev[curr.tag] = curr.displayName;

          return prev;
        }, {})
      : { 'Self Pay': 'Self Pay' };

  const conditionsOptions =
    (isFiltersSuccess &&
      filters.data.conditions.map((condition) => ({
        label: condition.displayName,
        value: condition.tag,
      }))) ||
    [];

  const admissibleMainFiltersOptions =
    (isFiltersSuccess &&
      filters.data.admissibleMainFilters.map((admissibleMainFilter) => ({
        label: admissibleMainFilter.displayName,
        value: admissibleMainFilter.tag,
      }))) ||
    [];

  return (
    <div className="firstFilters">
      <div className="home__container">
        <h2 className="firstFilters__title">Find your perfect match</h2>
        {isFiltersError && filtersError ? (
          <p>{filtersError.response?.data.message || filtersError.message}</p>
        ) : isCurrentInsuranceError && currentInsuranceError ? (
          <p>
            {currentInsuranceError.response?.data.message ||
              currentInsuranceError.message}
          </p>
        ) : (
          <div className="firstFilters__cards">
            <div className="firstFilters__card">
              <div className="firstFilters__photo" aria-hidden="true">
                <img
                  alt=""
                  width={112}
                  height={112}
                  src="/img/state.png"
                  srcSet="/img/state@2x.png 2x"
                  className="firstFilters__img"
                />
              </div>
              <Select
                name="state"
                id="state"
                placeholder="State"
                value={state || undefined}
                onValueChange={(value) => {
                  setState(value);
                  setInsurance('');
                }}
                triggerClassName="firstFilterSelect"
              >
                {statesOptions.map((option) => (
                  <SelectItem key={option.value} value={option.value}>
                    {option.label}
                  </SelectItem>
                ))}
              </Select>
              <div className="mobileSelect">
                <select
                  id="state"
                  name="state"
                  className="mobileSelect__trigger"
                  placeholder="State"
                  value={state || undefined}
                  onChange={(event) => setState(event.currentTarget.value)}
                >
                  <option value="" hidden>
                    State
                  </option>
                  {statesOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
                <div className="mobileSelect__arrow">
                  <svg
                    width="12"
                    height="12"
                    focusable="false"
                    aria-hidden="true"
                  >
                    <use xlinkHref="/icons.svg#arrow-down" />
                  </svg>
                </div>
              </div>
            </div>
            <div className="firstFilters__card">
              <div className="firstFilters__photo" aria-hidden="true">
                <img
                  alt=""
                  width={173}
                  height={112}
                  src="/img/insurance.png"
                  srcSet="/img/insurance@2x.png 2x"
                  className="firstFilters__img"
                />
              </div>
              <Select
                name="insurance"
                id="insurance"
                placeholder="Insurance"
                value={insurance || undefined}
                onValueChange={setInsurance}
                customValues={insurancesOptions}
                triggerClassName="firstFilterSelect"
              >
                {Object.entries(insurancesOptions).map((option) => (
                  <SelectItem key={option[0]} value={option[0]}>
                    {option[1]}
                  </SelectItem>
                ))}
              </Select>
              <div className="mobileSelect">
                <select
                  id="insurance"
                  name="insurance"
                  className="mobileSelect__trigger"
                  placeholder="Insurance"
                  value={insurance || undefined}
                  onChange={(event) => setInsurance(event.currentTarget.value)}
                >
                  <option value="" hidden>
                    Insurance
                  </option>
                  {Object.entries(insurancesOptions).map((option) => (
                    <option key={option[0]} value={option[0]}>
                      {option[1]}
                    </option>
                  ))}
                </select>
                <div className="mobileSelect__arrow">
                  <svg
                    width="12"
                    height="12"
                    focusable="false"
                    aria-hidden="true"
                  >
                    <use xlinkHref="/icons.svg#arrow-down" />
                  </svg>
                </div>
              </div>
            </div>
            <div className="firstFilters__card">
              <div className="firstFilters__photo" aria-hidden="true">
                <img
                  alt=""
                  width={112}
                  height={112}
                  src="/img/type.png"
                  srcSet="/img/type@2x.png 2x"
                  className="firstFilters__img"
                />
              </div>
              <Select
                name="ageCategory"
                id="ageCategory"
                placeholder="Type"
                value={ageCategory || undefined}
                onValueChange={setAgeCategory}
                triggerClassName="firstFilterSelect"
              >
                {admissibleMainFiltersOptions.map((option) => (
                  <SelectItem key={option.value} value={option.value}>
                    {option.label}
                  </SelectItem>
                ))}
              </Select>
              <div className="mobileSelect">
                <select
                  id="ageCategory"
                  name="ageCategory"
                  className="mobileSelect__trigger"
                  placeholder="Type"
                  value={ageCategory || undefined}
                  onChange={(event) =>
                    setAgeCategory(event.currentTarget.value)
                  }
                >
                  <option value="" hidden>
                    Type
                  </option>
                  {admissibleMainFiltersOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
                <div className="mobileSelect__arrow">
                  <svg
                    width="12"
                    height="12"
                    focusable="false"
                    aria-hidden="true"
                  >
                    <use xlinkHref="/icons.svg#arrow-down" />
                  </svg>
                </div>
              </div>
            </div>
            <div className="firstFilters__card">
              <div
                className="firstFilters__photo"
                aria-hidden="true"
                style={{ marginBottom: '0' }}
              >
                <img
                  alt=""
                  width={119}
                  height={119}
                  src="/img/focus.png"
                  srcSet="/img/focus@2x.png 2x"
                  className="firstFilters__img"
                />
              </div>
              <Select
                name="areaOfFocus"
                id="areaOfFocus"
                placeholder="Focus Area"
                value={areaOfFocus || undefined}
                onValueChange={setAreaOfFocus}
                triggerClassName="firstFilterSelect"
              >
                {conditionsOptions.map((option) => (
                  <SelectItem key={option.value} value={option.value}>
                    {option.label}
                  </SelectItem>
                ))}
              </Select>
              <div className="mobileSelect">
                <select
                  id="areaOfFocus"
                  name="areaOfFocus"
                  className="mobileSelect__trigger"
                  placeholder="Focus Area"
                  value={areaOfFocus || undefined}
                  onChange={(event) =>
                    setAreaOfFocus(event.currentTarget.value)
                  }
                >
                  <option value="" hidden>
                    Focus Area
                  </option>
                  {conditionsOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
                <div className="mobileSelect__arrow">
                  <svg
                    width="12"
                    height="12"
                    focusable="false"
                    aria-hidden="true"
                  >
                    <use xlinkHref="/icons.svg#arrow-down" />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
