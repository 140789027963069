import React from 'react';

export const Footer = () => {
  return (
    <div className="footer">
      <div className="footer__columns">
        <div>
          <a href="/">
            <img
              src="/img/logo.svg"
              width={152}
              height={33}
              alt="Logo"
              aria-hidden="true"
              className="footer-logo"
            />
          </a>
          <div className="footer__description">
            Equitable Access to High-Quality Mental HealthCare.
            <br />
            Our revolutionary Telehealth model enables us to
            <br />
            deliver high-quality mental health care to
          </div>
          <div className="footer__description-accent">
            Anyone, Anytime, Anywhere.
          </div>
        </div>
        <div>
          <div className="footer__reach-us">Reach us out</div>
          <div className="footer__reach-us-links">
            <div className="footer__reach-us-link">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
              >
                <path
                  d="M2.66671 2.6665H13.3334C14.0667 2.6665 14.6667 3.2665 14.6667 3.99984V11.9998C14.6667 12.7332 14.0667 13.3332 13.3334 13.3332H2.66671C1.93337 13.3332 1.33337 12.7332 1.33337 11.9998V3.99984C1.33337 3.2665 1.93337 2.6665 2.66671 2.6665Z"
                  stroke="#00563F"
                  strokeWidth="1.33333"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M14.6667 4L8.00004 8.66667L1.33337 4"
                  stroke="#00563F"
                  strokeWidth="1.33333"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <a
                className="footer-link-underline"
                href="mailto:info@cytipsych.com"
              >
                info@cytipsych.com
              </a>
            </div>
            <div className="footer__reach-us-link">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
              >
                <g clipPath="url(#clip0_989_7094)">
                  <path
                    d="M14.6667 11.2802V13.2802C14.6675 13.4659 14.6294 13.6497 14.555 13.8198C14.4807 13.9899 14.3716 14.1426 14.2348 14.2681C14.0979 14.3937 13.9364 14.4892 13.7605 14.5487C13.5847 14.6082 13.3983 14.6303 13.2134 14.6136C11.1619 14.3907 9.19137 13.6897 7.46004 12.5669C5.84926 11.5433 4.48359 10.1777 3.46004 8.56689C2.33336 6.8277 1.6322 4.84756 1.41337 2.78689C1.39671 2.60254 1.41862 2.41673 1.4777 2.24131C1.53679 2.06589 1.63175 1.90469 1.75655 1.76797C1.88134 1.63126 2.03324 1.52203 2.20256 1.44724C2.37189 1.37245 2.55493 1.33374 2.74004 1.33356H4.74004C5.06357 1.33038 5.37723 1.44495 5.62254 1.65592C5.86786 1.86689 6.02809 2.15986 6.07337 2.48023C6.15779 3.12027 6.31434 3.74871 6.54004 4.35356C6.62973 4.59218 6.64915 4.8515 6.59597 5.10081C6.5428 5.35012 6.41928 5.57897 6.24004 5.76023L5.39337 6.60689C6.34241 8.27592 7.72434 9.65786 9.39337 10.6069L10.24 9.76023C10.4213 9.58099 10.6501 9.45746 10.8994 9.40429C11.1488 9.35112 11.4081 9.37053 11.6467 9.46023C12.2516 9.68593 12.88 9.84248 13.52 9.92689C13.8439 9.97258 14.1396 10.1357 14.3511 10.3852C14.5625 10.6348 14.6748 10.9533 14.6667 11.2802Z"
                    stroke="#00563F"
                    strokeWidth="1.33333"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_989_7094">
                    <rect width="16" height="16" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <a className="footer-link-underline" href="tel:8664783978">
                866-478-3978
              </a>
            </div>
            <div className="footer__reach-us-link">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
              >
                <g clipPath="url(#clip0_989_7096)">
                  <path
                    d="M8.00004 14.6668C11.6819 14.6668 14.6667 11.6821 14.6667 8.00016C14.6667 4.31826 11.6819 1.3335 8.00004 1.3335C4.31814 1.3335 1.33337 4.31826 1.33337 8.00016C1.33337 11.6821 4.31814 14.6668 8.00004 14.6668Z"
                    stroke="#00563F"
                    strokeWidth="1.33333"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M1.33337 8H14.6667"
                    stroke="#00563F"
                    strokeWidth="1.33333"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M8.00004 1.3335C9.66756 3.15906 10.6152 5.52819 10.6667 8.00016C10.6152 10.4721 9.66756 12.8413 8.00004 14.6668C6.33252 12.8413 5.38487 10.4721 5.33337 8.00016C5.38487 5.52819 6.33252 3.15906 8.00004 1.3335Z"
                    stroke="#00563F"
                    strokeWidth="1.33333"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_989_7096">
                    <rect width="16" height="16" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <a className="footer-link" href="https://cyticlinics.com/">
                Cyticlinics Website
              </a>
            </div>
          </div>
          <div className="footer__socials">
            <a
              className="footer-link"
              href="https://www.facebook.com/cyticlinics/"
              target="_blank"
              rel="noreferrer"
            >
              <div className="footer__social">
                <svg
                  width="12"
                  height="13"
                  viewBox="0 0 12 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.88892 7.25H7.1311V12.5H4.78735V7.25H2.86548V5.09375H4.78735V3.42969C4.78735 1.55469 5.91235 0.5 7.62329 0.5C8.4436 0.5 9.31079 0.664062 9.31079 0.664062V2.51562H8.34985C7.41235 2.51562 7.1311 3.07812 7.1311 3.6875V5.09375H9.21704L8.88892 7.25Z"
                    fill="#00563F"
                  />
                </svg>
              </div>
            </a>
            <a
              className="footer-link"
              href="https://www.instagram.com/cytipsychological/"
              target="_blank"
              rel="noreferrer"
            >
              <div className="footer__social">
                <svg
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.10498 3.80469C7.58154 3.80469 8.80029 5.02344 8.80029 6.5C8.80029 8 7.58154 9.19531 6.10498 9.19531C4.60498 9.19531 3.40967 8 3.40967 6.5C3.40967 5.02344 4.60498 3.80469 6.10498 3.80469ZM6.10498 8.25781C7.06592 8.25781 7.83936 7.48438 7.83936 6.5C7.83936 5.53906 7.06592 4.76562 6.10498 4.76562C5.12061 4.76562 4.34717 5.53906 4.34717 6.5C4.34717 7.48438 5.14404 8.25781 6.10498 8.25781ZM9.52686 3.71094C9.52686 4.0625 9.24561 4.34375 8.89404 4.34375C8.54248 4.34375 8.26123 4.0625 8.26123 3.71094C8.26123 3.35938 8.54248 3.07812 8.89404 3.07812C9.24561 3.07812 9.52686 3.35938 9.52686 3.71094ZM11.3081 4.34375C11.355 5.21094 11.355 7.8125 11.3081 8.67969C11.2612 9.52344 11.0737 10.25 10.4644 10.8828C9.85498 11.4922 9.10498 11.6797 8.26123 11.7266C7.39404 11.7734 4.79248 11.7734 3.92529 11.7266C3.08154 11.6797 2.35498 11.4922 1.72217 10.8828C1.11279 10.25 0.925293 9.52344 0.878418 8.67969C0.831543 7.8125 0.831543 5.21094 0.878418 4.34375C0.925293 3.5 1.11279 2.75 1.72217 2.14062C2.35498 1.53125 3.08154 1.34375 3.92529 1.29688C4.79248 1.25 7.39404 1.25 8.26123 1.29688C9.10498 1.34375 9.85498 1.53125 10.4644 2.14062C11.0737 2.75 11.2612 3.5 11.3081 4.34375ZM10.1831 9.59375C10.4644 8.91406 10.394 7.27344 10.394 6.5C10.394 5.75 10.4644 4.10938 10.1831 3.40625C9.99561 2.96094 9.64404 2.58594 9.19873 2.42188C8.49561 2.14062 6.85498 2.21094 6.10498 2.21094C5.33154 2.21094 3.69092 2.14062 3.01123 2.42188C2.54248 2.60938 2.19092 2.96094 2.00342 3.40625C1.72217 4.10938 1.79248 5.75 1.79248 6.5C1.79248 7.27344 1.72217 8.91406 2.00342 9.59375C2.19092 10.0625 2.54248 10.4141 3.01123 10.6016C3.69092 10.8828 5.33154 10.8125 6.10498 10.8125C6.85498 10.8125 8.49561 10.8828 9.19873 10.6016C9.64404 10.4141 10.019 10.0625 10.1831 9.59375Z"
                    fill="#00563F"
                  />
                </svg>
              </div>
            </a>
          </div>
        </div>
      </div>
      <div className="footer__divider"></div>
      <div className="footer__copyright-wrap">
        <div className="footer__copyright-left">
          <div className="footer__copyright-text">
            © Copyright Cyti Clinics. All rights reserved.
          </div>
          <div className="footer__copyright-verified">
            <a
              href="https://www.psychologytoday.com/us/therapists/cyti-psychological-san-diego-ca/845457"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="/img/verified_by_psychology_today.png"
                width={102}
                height={32}
                alt="Logo"
                aria-hidden="true"
              />
            </a>
            <img
              src="/img/accredited_business.png"
              width={42}
              height={29}
              alt="Logo"
              aria-hidden="true"
            />
          </div>
        </div>
        <a
          className="footer-link"
          href="https://cyticlinics.com/terms-conditions/"
          target="_blank"
          rel="noreferrer"
        >
          <div className="footer__copyright-text">Terms &amp; Conditions</div>
        </a>
      </div>
    </div>
  );
};
