/* eslint-disable indent */
import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { Info } from './components/Info';
import { Empty } from './components/Empty';
import { Filters } from './components/Filters';
import { Loader } from '../../components/Loader';
import { Header } from '../../components/Header';
import { Footer } from '../../components/Footer';
import { FirstFilters } from './components/FirstFilters';
import { ProviderCard } from './components/ProviderCard';
import { SignupDialog } from './components/SignupDialog';

import { useProviders } from './api/queries/useProviders';

import { useLazyLoadImages } from '../../hooks/useLazyLoadImages';

import { ProviderDialogType } from '../../@types/providerDialog';

export const NewPatientPage: React.FC = () => {
  const search = useLocation().search;
  const searchParams = new URLSearchParams(search);
  const title = searchParams.get('utm_source') || '';

  const providersWrapperDiv = useRef<HTMLDivElement>(null);

  const [signupDialogState, setSignupDialogState] = useState(false);
  const [dialogData, setDialogData] = useState<ProviderDialogType | null>(null);

  const [isFirstFiltersUsed, setFirstFiltersUsed] = useState(false);

  const [state, setState] = useState('');
  const [insurance, setInsurance] = useState('');
  const [ageCategory, setAgeCategory] = useState('');
  const [areaOfFocus, setAreaOfFocus] = useState('');
  const [gender, setGender] = useState('');
  const [language, setLanguage] = useState('');

  const {
    isSuccess,
    isFetching,
    isError,
    error,
    data: providersData,
  } = useProviders({
    state,
    insurance,
    condition: areaOfFocus,
    admissible: ageCategory,
    gender,
    language,
  });

  const handleClearDialogData = () => {
    setDialogData(null);
    setSignupDialogState(false);
  };

  useLazyLoadImages(providersWrapperDiv, []);

  useEffect(() => {
    if (state && insurance && ageCategory && !isFirstFiltersUsed) {
      setFirstFiltersUsed(true);
    }
  }, [state, insurance, ageCategory, isFirstFiltersUsed]);

  return (
    <div className="home">
      <div className="home__wrapper">
        <Header />
        <div className="home__container">
          <Info />
          {isFirstFiltersUsed && (
            <Filters
              state={state}
              setState={setState}
              insurance={insurance}
              setInsurance={setInsurance}
              ageCategory={ageCategory}
              setAgeCategory={setAgeCategory}
              areaOfFocus={areaOfFocus}
              setAreaOfFocus={setAreaOfFocus}
              language={language}
              setLanguage={setLanguage}
              gender={gender}
              setGender={setGender}
              providersDataLength={providersData?.data.providers.length}
            />
          )}
        </div>
      </div>
      {state && ageCategory && insurance ? (
        <div className="home__container">
          <div>
            {isFetching ? (
              <div style={{ margin: '100px auto 0 auto' }}>
                <Loader color="#D6D6D6" />
              </div>
            ) : isError && error ? (
              <p className="home__subText">
                {error.response?.data.message || error.message}
              </p>
            ) : isSuccess && providersData.data.providers.length ? (
              <div className="home__providers" ref={providersWrapperDiv}>
                {providersData.data.providers.map((provider) => (
                  <ProviderCard
                    key={`${provider.id}-${provider.firstName}-${provider.email}`}
                    data={provider}
                    onClickMoreTimes={() => {
                      setDialogData({
                        insurance,
                        state,
                        condition: areaOfFocus,
                        typeOfSession: ageCategory,
                        language,
                        gender,
                        provider,
                        selectedSlot: null,
                        appointmentId: providersData.data.appointmentId,
                        assessmentTypeIds: providersData.data.assessmentTypeIds,
                        title,
                        isOpenFromProviderDialog: false,
                      });
                      setSignupDialogState(true);
                    }}
                    onClickGetMatched={(val) => {
                      window.dataLayer.push({
                        event: 'booking_signup_step1',
                        state,
                        insurance,
                        area_of_focus: areaOfFocus,
                        appointment_type: ageCategory,
                        therapist_name: `${provider.firstName} ${provider.lastName}`,
                        therapist_gender: provider.gender,
                      });

                      setDialogData({
                        insurance,
                        state,
                        condition: areaOfFocus,
                        typeOfSession: ageCategory,
                        language,
                        gender,
                        provider,
                        selectedSlot: val,
                        appointmentId: providersData.data.appointmentId,
                        assessmentTypeIds: providersData.data.assessmentTypeIds,
                        title,
                        isOpenFromProviderDialog: false,
                      });
                      setSignupDialogState(true);
                    }}
                  />
                ))}
              </div>
            ) : isSuccess && !providersData.data.providers.length ? (
              <Empty />
            ) : null}
          </div>
        </div>
      ) : (
        <>
          {!isFirstFiltersUsed && (
            <FirstFilters
              state={state}
              setState={setState}
              insurance={insurance}
              setInsurance={setInsurance}
              ageCategory={ageCategory}
              setAgeCategory={setAgeCategory}
              areaOfFocus={areaOfFocus}
              setAreaOfFocus={setAreaOfFocus}
            />
          )}
        </>
      )}
      {dialogData && (
        <SignupDialog
          dialogData={dialogData}
          dialogState={signupDialogState}
          setDialogState={setSignupDialogState}
          handleClearDialogData={handleClearDialogData}
        />
      )}
      <Footer />
    </div>
  );
};
