import React, { useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import Match from '../pages/match';
import { NewPatientPage } from '../pages/new-patient-revision';
import RequestTime from '../pages/request-time';

export const RouterProvider: React.FC = () => {
  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<NewPatientPage />} />
        <Route path="/match" element={<Match />} />
        <Route path="/request-time" element={<RequestTime />} />
      </Routes>
    </BrowserRouter>
  );
};
