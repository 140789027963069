import React, { ReactNode, forwardRef } from 'react';
import * as PopoverPrimitive from '@radix-ui/react-popover';

export const Popover = PopoverPrimitive.Root;
export const PopoverTrigger = PopoverPrimitive.Trigger;

interface PopoverContentProps extends PopoverPrimitive.PopoverContentProps {
  children: ReactNode;
}

type Ref = HTMLDivElement;

export const PopoverContent = forwardRef<Ref, PopoverContentProps>(
  ({ children, ...props }, forwardedRef) => (
    <PopoverPrimitive.Portal
      container={document.body.querySelector('#portal') as HTMLElement}
    >
      <PopoverPrimitive.Content
        sideOffset={5}
        {...props}
        ref={forwardedRef}
        className="popover__content"
      >
        {children}
      </PopoverPrimitive.Content>
    </PopoverPrimitive.Portal>
  ),
);

PopoverContent.displayName = 'PopoverContent';
