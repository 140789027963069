import React, { FC } from 'react';
import { Link } from 'react-router-dom';

export const Info: FC = () => {
  return (
    <div className="home__info">
      <div style={{ maxWidth: '791px' }}>
        <h1 className="home__title">Welcome</h1>
        <p className="home__text">
          We’re glad you’re here. Please use the filters below to view our
          therapists and schedule online. <br className="home__br" /> Or{' '}
          <Link to="/match" className="home__link">
            Let Us Match You
          </Link>{' '}
          and we will reach out to personally match you with your ideal
          therapist
        </p>
      </div>
      <div className="home__dec" aria-hidden="true">
        <img
          src="/img/dec.png"
          srcSet="/img/dec@2x.png 2x"
          width={283}
          height={189}
          alt=""
          className="home__img"
        />
      </div>
    </div>
  );
};
