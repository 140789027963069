import { AxiosError } from 'axios';
import { useMutation } from '@tanstack/react-query';

import { api } from '../../../../services/apiUtils';

import { ApiError } from '../../../../@types/api';

export const useMatchedWithUser = () => {
  const token = localStorage.getItem('token');

  return useMutation<
    { status: 'OK' },
    AxiosError<ApiError>,
    {
      body: {
        firstName: string;
        lastName: string;
        birthDate: string;
        gender: string; // (from admissible filter)
        phone: string;
        email: string;
        insuranceProvider?: string;
        insuranceId?: string;
        referredBy?: string;
        referralSource: string; // from referral source endpoint
      };
    }
  >({
    mutationFn: async ({ body }) => {
      const { data } = await api.post<{ status: 'OK' }>(
        `/users/match`,
        JSON.stringify(body),
        {
          headers: {
            Authorization: token ? `Token ${token}` : '',
          },
        },
      );
      return data;
    },
  });
};
