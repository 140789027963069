import React, { FC, MouseEvent } from 'react';
import { ActiveModifiers, DayPicker } from 'react-day-picker';

import { CustomCaption } from './CustomCaption';
import { Popover, PopoverContent, PopoverTrigger } from './Popover';

type SelectRangeDateProps = {
  date: Date | undefined;
  soonestAvailableDate: Date;
  setDate: (
    day: Date | undefined,
    selectedDay: Date,
    activeModifiers: ActiveModifiers,
    e: MouseEvent,
  ) => void;
};

export const PopoverCalendar: FC<SelectRangeDateProps> = ({
  date,
  soonestAvailableDate,
  setDate,
}) => {
  return (
    <Popover>
      <PopoverTrigger asChild>
        <button className="popoverCalendar__btn">
          <svg width="20" height="20" focusable="false" aria-hidden="true">
            <use xlinkHref="/icons.svg#calendar" />
          </svg>
        </button>
      </PopoverTrigger>
      <PopoverContent align="end">
        <DayPicker
          mode="single"
          showOutsideDays
          weekStartsOn={1}
          className="calendar"
          selected={date}
          onSelect={setDate}
          fromDate={soonestAvailableDate}
          components={{
            Caption: CustomCaption,
          }}
        />
      </PopoverContent>
    </Popover>
  );
};
