import { AxiosError } from 'axios';
import { useMutation } from '@tanstack/react-query';

import { api } from '../../../../services/apiUtils';

import { ApiError } from '../../../../@types/api';

export const useCreateAppointment = () => {
  return useMutation<
    { status: 'OK' },
    AxiosError<ApiError>,
    {
      body: {
        userId: number; // from create user api
        email: string;
        firstName: string;
        lastName: string;
        birthDate: string;
        gender: string;
        phone: string;
        referredBy?: string;
        referralSource: string;
        providerId: number; // from providers api
        startDate: string; // 2023-08-25 13:00:00 format (from providers available slots)
        endDate: string; // 2023-08-25 13:00:00 format (from providers available slots)
        appointmentId: number; // from providers response
        assessmentTypeIds: number[]; // from providers response
      };
    }
  >({
    mutationFn: async ({ body }) => {
      const { data } = await api.post<{ status: 'OK' }>(
        `/users/appointment`,
        JSON.stringify(body),
      );
      return data;
    },
  });
};
