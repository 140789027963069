import React from 'react';
import { format } from 'date-fns';
import { CaptionProps, useNavigation } from 'react-day-picker';

export const CustomCaption = (props: CaptionProps) => {
  const { goToMonth, nextMonth, previousMonth } = useNavigation();

  return (
    <div className="calendar__caption">
      <button
        disabled={!previousMonth}
        onClick={() => previousMonth && goToMonth(previousMonth)}
        className="calendar__prev"
      >
        <svg width="16" height="16" focusable="false" aria-hidden="true">
          <use xlinkHref="/icons.svg#chevronLeft" />
        </svg>
      </button>
      <p className="calendar__month">
        {format(props.displayMonth, 'MMMM yyy')}
      </p>
      <button
        disabled={!nextMonth}
        onClick={() => nextMonth && goToMonth(nextMonth)}
        className="calendar__next"
      >
        <svg width="16" height="16" focusable="false" aria-hidden="true">
          <use xlinkHref="/icons.svg#chevronRight" />
        </svg>
      </button>
    </div>
  );
};
