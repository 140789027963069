import React, { FC, Dispatch, useState, SetStateAction } from 'react';
import dayjs from 'dayjs';
import { useForm } from 'react-hook-form';

import { SignupDialogFinish } from './SignupDialogFinish';
import { SignupDialogDetails } from './SignupDialogDetails';
import { SignupDialogProvider } from './SignupDialogProvider';
import { FormValues, SignupDialogForm } from './SignupDialogForm';
import { DialogContent, DialogRoot } from '../../../components/Dialog';

import { ProviderSlotType } from '../../../@types/api';
import { ProviderDialogType } from '../../../@types/providerDialog';

type SignupDialogProps = {
  dialogState: boolean;
  dialogData: ProviderDialogType;
  setDialogState: Dispatch<SetStateAction<boolean>>;
  handleClearDialogData: () => void;
};

export const SignupDialog: FC<SignupDialogProps> = ({
  dialogState,
  dialogData,
  setDialogState,
  handleClearDialogData,
}) => {
  const [currentSlide, setCurrentSlide] = useState<-1 | 0 | 1 | 2>(
    dialogData.selectedSlot ? 0 : -1,
  );
  const [selectedSlot, setSelectedSlot] = useState(dialogData.selectedSlot);
  const [formValues, setFormValues] = useState<FormValues | null>(null);

  const changeCurrentSlide = (value: 0 | 1 | 2) => {
    setCurrentSlide(value);
  };

  const {
    watch,
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      firstName: '',
      lastName: '',
      birthDate: undefined,
      gender: '',
      phone: '',
      email: '',
      referralSource: '',
      referredBy: '',
    },
  });

  const wathcReferralSourse = watch('referralSource');

  const handleSubmitForm = (formValues: FormValues) => {
    window.dataLayer.push({
      event: 'booking_signup_step2',
      state: dialogData.state || 'California',
      insurance: dialogData.insurance || null,
      area_of_focus: dialogData.condition || null,
      appointment_type: dialogData.typeOfSession || null,
      therapist_name:
        `${dialogData.provider.firstName} ${dialogData.provider.lastName}` ||
        {},
      therapist_gender: dialogData.provider.gender || {},
      user_bd: dayjs(formValues.birthDate).format('YYYY-MM-DD'),
      user_gender: formValues.gender,
    });
    setFormValues(formValues);
    setCurrentSlide(1);
  };

  const handleChooseSlot = (slot: ProviderSlotType) => {
    setSelectedSlot(slot);
    setCurrentSlide(0);
  };

  return (
    <DialogRoot open={dialogState} onOpenChange={setDialogState}>
      <DialogContent title="Signup dialog" closeHandler={handleClearDialogData}>
        <div className="signupDialog">
          {currentSlide === -1 && (
            <>
              <div className="signupDialog__header">
                <button
                  className="signupDialog__back"
                  onClick={() => handleClearDialogData()}
                >
                  <svg
                    width="20"
                    height="20"
                    focusable="false"
                    aria-hidden="true"
                  >
                    <use xlinkHref="/icons.svg#arrow-back" />
                  </svg>
                  Back to all
                </button>
                <button
                  className="dialog__close"
                  aria-label="Close"
                  onClick={() => handleClearDialogData()}
                >
                  <svg width="20" height="20" focusable="false">
                    <use xlinkHref="/icons.svg#close" />
                  </svg>
                </button>
              </div>
              <SignupDialogProvider
                dialogData={dialogData}
                handleChooseSlot={handleChooseSlot}
              />
            </>
          )}
          {currentSlide === 0 && selectedSlot && (
            <>
              <div className="signupDialog__header">
                <button
                  className="signupDialog__back"
                  onClick={() => setCurrentSlide(-1)}
                >
                  <svg
                    width="20"
                    height="20"
                    focusable="false"
                    aria-hidden="true"
                  >
                    <use xlinkHref="/icons.svg#arrow-back" />
                  </svg>
                  Back to psychologist card
                </button>
                <button
                  className="dialog__close"
                  aria-label="Close"
                  onClick={() => handleClearDialogData()}
                >
                  <svg width="20" height="20" focusable="false">
                    <use xlinkHref="/icons.svg#close" />
                  </svg>
                </button>
              </div>
              <SignupDialogForm
                errors={errors}
                control={control}
                wathcReferralSourse={wathcReferralSourse}
                register={register}
                onSubmit={handleSubmitForm}
                handleSubmit={handleSubmit}
              />
            </>
          )}
          {currentSlide === 1 && formValues && dialogData && selectedSlot && (
            <>
              <div className="signupDialog__header">
                <button
                  className="signupDialog__back"
                  onClick={() => setCurrentSlide(0)}
                >
                  <svg
                    width="20"
                    height="20"
                    focusable="false"
                    aria-hidden="true"
                  >
                    <use xlinkHref="/icons.svg#arrow-back" />
                  </svg>
                  Back to edit
                </button>
                <button
                  className="dialog__close"
                  aria-label="Close"
                  onClick={() => handleClearDialogData()}
                >
                  <svg width="2020" height="2020" focusable="false">
                    <use xlinkHref="/icons.svg#close" />
                  </svg>
                </button>
              </div>
              <SignupDialogDetails
                formValues={formValues}
                dialogData={dialogData}
                selectedSlot={selectedSlot}
                changeCurrentSlide={changeCurrentSlide}
              />
            </>
          )}
          {currentSlide === 2 && (
            <>
              <div className="signupDialog__header">
                <button
                  className="dialog__close"
                  aria-label="Close"
                  onClick={() => handleClearDialogData()}
                >
                  <svg width="20" height="20" focusable="false">
                    <use xlinkHref="/icons.svg#close" />
                  </svg>
                </button>
              </div>
              <SignupDialogFinish />
            </>
          )}
        </div>
      </DialogContent>
    </DialogRoot>
  );
};
