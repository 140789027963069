import { AxiosError } from 'axios';
import { useQuery } from '@tanstack/react-query';

import { api } from '../../../../services/apiUtils';

import {
  ApiError,
  ApiResult,
  GetProviderResponse,
  ProviderFiltersType,
} from '../../../../@types/api';

export const useProviderSlots = ({
  state,
  insurance,
  condition,
  admissible,
  gender,
  language,
  startDate,
  providerId,
  appointmentTypeId,
}: ProviderFiltersType) => {
  return useQuery<ApiResult<GetProviderResponse>, AxiosError<ApiError>>({
    queryKey: [
      'providers',
      state,
      insurance,
      condition,
      admissible,
      gender,
      language,
      startDate,
      providerId,
      appointmentTypeId,
    ],
    queryFn: async () => {
      const { data } = await api.get<ApiResult<GetProviderResponse>>(
        `/providers/calendar?state=${state}&insurance=${insurance}&condition=${condition}&admissible=${admissible}&providerId=${providerId}&startDate=${startDate}&appointmentTypeId=${appointmentTypeId}${
          gender ? `&gender=${gender}` : ''
        }${language ? `&language=${language}` : ''}`,
      );

      return data;
    },
    enabled:
      !!state && !!insurance && !!admissible && !!startDate && !!providerId,
    refetchOnWindowFocus: false,
  });
};
