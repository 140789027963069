import React, { FC } from 'react';
import {
  Control,
  Controller,
  FieldErrors,
  UseFormRegister,
  UseFormHandleSubmit,
} from 'react-hook-form';
import { useQueryClient } from '@tanstack/react-query';

import { InputRhf } from '../../../components/form/InputRhf';
import { Select, SelectItem } from '../../../components/Select';

import { useReferral } from '../../../hooks/useReferral';

import { personReferralCategories } from '../../../constants/referralCategories';
import { ApiResult, GetFiltersResponse } from '../../../@types/api';

export type FormValues = {
  firstName: string;
  lastName: string;
  birthDate: string;
  gender: string;
  phone: string;
  email: string;
  referralSource: string;
  referredBy: string;
};

type SignupDialogFormProps = {
  errors: FieldErrors<FormValues>;
  control: Control<FormValues, any>;
  wathcReferralSourse: string;
  register: UseFormRegister<FormValues>;
  onSubmit: (formValues: FormValues) => void;
  handleSubmit: UseFormHandleSubmit<FormValues, undefined>;
};

export const SignupDialogForm: FC<SignupDialogFormProps> = ({
  errors,
  control,
  wathcReferralSourse,
  register,
  onSubmit,
  handleSubmit,
}) => {
  const queryClient = useQueryClient();
  const filters = queryClient.getQueryData<ApiResult<GetFiltersResponse>>([
    'filters',
  ]);

  const {
    isSuccess: isReferralSuccess,
    isError: isReferralError,
    error: referralError,
    data: referralData,
  } = useReferral();

  const genders =
    (filters &&
      filters.data.admissibleAdditionalFilters.genders.reduce<
        Record<string, string>
      >((prev, curr) => {
        prev[curr] = curr;
        return prev;
      }, {})) ||
    {};

  const referralSources = referralData?.data.reduce<Record<string, string>>(
    (prev, curr) => {
      prev[curr] = curr;
      return prev;
    },
    {},
  );

  return (
    <>
      <h2 className="signupDialog__title">Nice to meet you</h2>
      <p className="signupDialog__text">
        Your appointment is NOT confirmed yet. You must complete and finish your
        profile to confirm your appointment.
      </p>
      <form
        className="form signupDialog__form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <p className="form__legend">Personal details</p>
        <fieldset className="form__fieldset form__fieldset--small form__fieldset--double">
          <InputRhf<FormValues>
            hideLabel
            type="text"
            name="firstName"
            label="First Name"
            register={register}
            placeholder="First Name"
            autoComplete="given-name"
            rules={{
              required: { value: true, message: 'Required' },
            }}
            error={errors.firstName}
          />
          <InputRhf<FormValues>
            hideLabel
            type="text"
            name="lastName"
            label="Last Name"
            register={register}
            placeholder="Last Name"
            autoComplete="family-name"
            rules={{
              required: { value: true, message: 'Required' },
            }}
            error={errors.lastName}
          />
          <InputRhf<FormValues>
            hideLabel
            type="date"
            name="birthDate"
            label="Birth Date"
            register={register}
            placeholder="Birth Date"
            autoComplete="bday"
            rules={{
              required: { value: true, message: 'Required' },
              validate: (value: string) => {
                const selectedDate = new Date(value);
                const currentDate = new Date();

                return (
                  currentDate.getFullYear() - selectedDate.getFullYear() >=
                    13 || 'Should be greater than 18'
                );
              },
            }}
            error={errors.birthDate}
          />
          <Controller
            name="gender"
            control={control}
            rules={{ required: { value: true, message: 'Required' } }}
            render={({ field }) => (
              <Select
                id="gender"
                placeholder="Your gender"
                {...field}
                error={errors.gender}
                value={field.value}
                onValueChange={field.onChange}
                customValues={genders}
              >
                {Object.entries(genders).map((value) => (
                  <SelectItem key={value[0]} value={value[0]}>
                    {value[1]}
                  </SelectItem>
                ))}
              </Select>
            )}
          />
        </fieldset>
        <p className="form__legend">Contact details</p>
        <fieldset className="form__fieldset form__fieldset--small form__fieldset--double">
          <InputRhf<FormValues>
            hideLabel
            type="tel"
            name="phone"
            label="Phone"
            register={register}
            autoComplete="tel"
            placeholder="Phone"
            rules={{
              required: { value: true, message: 'Required' },
              minLength: { value: 10, message: 'Min length is 10' },
              maxLength: { value: 10, message: 'Max length is 10' },
            }}
            error={errors.phone}
          />
          <InputRhf<FormValues>
            hideLabel
            type="email"
            name="email"
            label="Email"
            register={register}
            placeholder="Email"
            autoComplete="email"
            rules={{
              required: { value: true, message: 'Required' },
            }}
            error={errors.email}
          />
        </fieldset>
        <p className="form__legend">How did you hear about Cyti?</p>
        {isReferralError && referralError ? (
          <p className="home__subText">
            {referralError.response?.data.message || referralError.message}
          </p>
        ) : (
          <fieldset className="form__fieldset form__fieldset--small form__fieldset--double">
            <Controller
              name="referralSource"
              control={control}
              rules={{ required: { value: true, message: 'Required' } }}
              render={({ field }) => (
                <Select
                  id="referralSource"
                  placeholder="Referral Category"
                  {...field}
                  error={errors.referralSource}
                  value={field.value}
                  onValueChange={field.onChange}
                  customValues={referralSources}
                >
                  {isReferralSuccess &&
                    referralData.data.map((value) => (
                      <SelectItem key={value} value={value}>
                        {value}
                      </SelectItem>
                    ))}
                </Select>
              )}
            />
            {personReferralCategories.includes(wathcReferralSourse) && (
              <InputRhf<FormValues>
                hideLabel
                type="text"
                name="referredBy"
                label="Referred By"
                register={register}
                placeholder="Referred By (optional)"
                error={errors.referredBy}
              />
            )}
          </fieldset>
        )}
        <div className="form__save">
          <button type="submit" className="form__btn">
            Next
          </button>
        </div>
      </form>
    </>
  );
};
