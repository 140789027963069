import { AxiosError } from 'axios';
import { useQuery } from '@tanstack/react-query';

import { api } from '../../../../services/apiUtils';

import {
  ApiError,
  ApiResult,
  GetProvidersResponse,
  ProvidersFiltersType,
} from '../../../../@types/api';

export const useProviders = ({
  state,
  insurance,
  condition,
  admissible,
  gender,
  language,
}: ProvidersFiltersType) => {
  return useQuery<ApiResult<GetProvidersResponse>, AxiosError<ApiError>>({
    queryKey: [
      'providers',
      state,
      insurance,
      condition,
      admissible,
      gender,
      language,
    ],
    queryFn: async () => {
      const { data } = await api.get<ApiResult<GetProvidersResponse>>(
        `/providers?state=${state}&insurance=${insurance}&condition=${condition}&admissible=${admissible}${
          gender ? `&gender=${gender}` : ''
        }${language ? `&language=${language}` : ''}`,
      );

      return data;
    },
    enabled: !!state && !!insurance && !!admissible,
    refetchOnWindowFocus: false,
  });
};
