import React, { FC, useState } from 'react';
import dayjs from 'dayjs';

import { onImageError } from '../../../utils/image';

import { ProviderSlotType, ProviderType } from '../../../@types/api';
import { Link } from 'react-router-dom';

type ProviderCardProps = {
  data: ProviderType;
  onClickMoreTimes: () => void;
  onClickGetMatched: (val: ProviderSlotType) => void;
};

export const ProviderCard: FC<ProviderCardProps> = ({
  data,
  onClickMoreTimes,
  onClickGetMatched,
}) => {
  const [expand, setExpand] = useState(false);

  const handleExpand = () => {
    setExpand((prev) => !prev);
  };

  return (
    <article className="providerCard">
      <header className="providerCard__header">
        <div className="providerCard__photo">
          <div className="providerCard__dec" />
          <img
            width={80}
            height={80}
            role="presentation"
            data-src={data.photo}
            onError={onImageError}
            className="providerCard__img"
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
          />
        </div>
        <h2 className="providerCard__name">
          {data.firstName} {data.lastName}
        </h2>
        {data.about && (
          <p className="providerCard__bio">
            {data.about
              .replaceAll('<p>', '')
              .replaceAll('</p>', '')
              .slice(
                0,
                data.about.length > 300 && !expand ? 300 : data.about.length,
              )}{' '}
            {data.about.length > 300 ? (
              <button
                type="button"
                className="providerCard__expand"
                onClick={handleExpand}
              >
                {expand ? 'Show less' : 'Show more'}
              </button>
            ) : null}
          </p>
        )}
      </header>
      <footer className="providerCard__footer">
        {data.availableSlots.length > 0 && (
          <p className="providerCard__time">1st Available Times</p>
        )}
        <div className="providerCard__slots">
          {data.availableSlots.slice(0, 3).map((slot) => (
            <button
              key={slot.userId}
              type="button"
              className="providerCard__slot"
              onClick={() => onClickGetMatched(slot)}
            >
              {dayjs.utc(slot.startDate).tz('PST').format('MMM DD hh:mmA')}
            </button>
          ))}
        </div>
        {data.availableSlots.length < 1 && (
          <Link
            className="request-time-link"
            to={`/request-time?id=${data.id}`}
          >
            Request a Time with {data.firstName}
          </Link>
        )}
        {data.availableSlots.length > 0 && (
          <button
            type="button"
            className="providerCard__btn"
            onClick={onClickMoreTimes}
          >
            More times
          </button>
        )}
      </footer>
    </article>
  );
};
