import React, { FC, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';

import { Loader } from '../../components/Loader';
import { Header } from '../../components/Header';
import { InputRhf } from '../../components/form/InputRhf';
import { Select, SelectItem } from '../../components/Select';

import { useReferral } from '../../hooks/useReferral';
import { useFilters } from '../new-patient-revision/api/queries/useFilters';
import { useMatchedWithUser } from './api/mutations/useMatchedWithUser';

import { personReferralCategories } from '../../constants/referralCategories';
import { Footer } from '../../components/Footer';

type FormValues = {
  firstName: string;
  lastName: string;
  birthDate: string;
  gender: string;
  phone: string;
  email: string;
  insuranceProvider?: string;
  insuranceId?: string;
  referralSource: string;
  referredBy?: string;
};

const Match: FC = () => {
  const {
    isError: isFiltersError,
    error: filtersError,
    data: filters,
  } = useFilters();

  const genders =
    (filters &&
      filters.data.admissibleAdditionalFilters.genders.reduce<
        Record<string, string>
      >((prev, curr) => {
        prev[curr] = curr;
        return prev;
      }, {})) ||
    {};

  const {
    isSuccess: isReferralSuccess,
    isError: isReferralError,
    error: referralError,
    data: referralData,
  } = useReferral();

  const referralSources = referralData?.data.reduce<Record<string, string>>(
    (prev, curr) => {
      prev[curr] = curr;
      return prev;
    },
    {},
  );

  const { isSuccess, isLoading, isError, error, data, mutate } =
    useMatchedWithUser();

  const {
    watch,
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      firstName: '',
      lastName: '',
      birthDate: undefined,
      gender: '',
      phone: '',
      email: '',
      insuranceProvider: '',
      insuranceId: '',
      referralSource: '',
      referredBy: '',
    },
  });

  const wathcReferralSourse = watch('referralSource');

  const onSubmit = (formValues: FormValues) => {
    mutate({ body: formValues });
  };

  useEffect(() => {
    if (isSuccess && data.status === 'OK') {
      window.dataLayer.push({
        event: 'form_submit_let_us_match_you',
      });
    }
  }, [isSuccess, data]);

  return (
    <div className="match">
      <div className="match__wrapper">
        <Header />
        <div className="match__container">
          <Link to="/" className="match__back">
            <svg width="24" height="24" focusable="false" aria-hidden="true">
              <use xlinkHref="/icons.svg#arrow-back" />
            </svg>
            Back
          </Link>
          <div className="match__info">
            <h1 className="match__title">
              {isSuccess
                ? 'Congratulations on taking the first step'
                : 'Let Us Match You'}
            </h1>
            {isSuccess ? (
              <p className="match__text">
                Your Cyti Care Coordinator will reach out to you within 48 hrs
                to get journey started. If you have any questions, please don’t
                hesitate to call our team at{' '}
                <a href="tel:1-866-478-3978" className="match__phone">
                  1-866-478-3978
                </a>
              </p>
            ) : (
              <p className="match__text">
                Please fill out the form below and a Care Coordinator will
                contact you shortly. Or you can call{' '}
                <a href="tel:1-866-478-3978" className="match__phone">
                  1-866-478-3978
                </a>{' '}
                to speak to a Specialist immediately.
              </p>
            )}
            {isSuccess && (
              <p className="match__subText">
                Holiday hours may extend our call back times
              </p>
            )}
            {isSuccess && (
              <p className="match__help">
                If you are experiencing a medical emergency, please cal{' '}
                <span className="match__help--bold">911</span> immediately. If
                you are experiencing a mental health emergency, suicidal
                ideation or if you are considering taking actions that may cause
                harm to yourself, please call the national network of suicide
                and crisis lifelines at{' '}
                <span className="match__help--bold">988</span>
              </p>
            )}
          </div>
          {!isSuccess && (
            <form
              className="form match__form"
              onSubmit={handleSubmit(onSubmit)}
            >
              <p className="form__legend">Personal details</p>
              <fieldset className="form__fieldset form__fieldset--double">
                <InputRhf<FormValues>
                  hideLabel
                  type="text"
                  name="firstName"
                  label="First Name"
                  register={register}
                  placeholder="First Name"
                  rules={{
                    required: { value: true, message: 'Required' },
                  }}
                  error={errors.firstName}
                />
                <InputRhf<FormValues>
                  hideLabel
                  type="text"
                  name="lastName"
                  label="Last Name"
                  register={register}
                  placeholder="Last Name"
                  rules={{
                    required: { value: true, message: 'Required' },
                  }}
                  error={errors.lastName}
                />
                <InputRhf<FormValues>
                  hideLabel
                  type="date"
                  name="birthDate"
                  label="Birth Date"
                  register={register}
                  placeholder="Birth Date"
                  autoComplete="bday"
                  rules={{
                    required: { value: true, message: 'Required' },
                    validate: (value: string) => {
                      const selectedDate = new Date(value);
                      const currentDate = new Date();

                      return (
                        currentDate.getFullYear() -
                          selectedDate.getFullYear() >=
                          13 || 'Should be greater than 18'
                      );
                    },
                  }}
                  error={errors.birthDate}
                />
                {isFiltersError && filtersError ? (
                  <p>
                    {filtersError.response?.data.message ||
                      filtersError.message}
                  </p>
                ) : (
                  <Controller
                    name="gender"
                    control={control}
                    rules={{ required: { value: true, message: 'Required' } }}
                    render={({ field }) => (
                      <Select
                        id="gender"
                        placeholder="Your gender"
                        {...field}
                        error={errors.gender}
                        value={field.value}
                        onValueChange={field.onChange}
                        customValues={genders}
                      >
                        {Object.entries(genders).map((value) => (
                          <SelectItem key={value[0]} value={value[0]}>
                            {value[1]}
                          </SelectItem>
                        ))}
                      </Select>
                    )}
                  />
                )}
              </fieldset>
              <p className="form__legend">Contact details</p>
              <fieldset className="form__fieldset form__fieldset--double">
                <InputRhf<FormValues>
                  hideLabel
                  type="tel"
                  name="phone"
                  label="Phone"
                  register={register}
                  placeholder="Phone"
                  rules={{
                    required: { value: true, message: 'Required' },
                    minLength: { value: 10, message: 'Min length is 10' },
                    maxLength: { value: 10, message: 'Max length is 10' },
                  }}
                  error={errors.phone}
                />
                <InputRhf<FormValues>
                  hideLabel
                  type="email"
                  name="email"
                  label="Email"
                  register={register}
                  placeholder="Email"
                  rules={{
                    required: { value: true, message: 'Required' },
                  }}
                  error={errors.email}
                />
              </fieldset>
              <p className="form__legend">Insurance details (optional)</p>
              <fieldset className="form__fieldset form__fieldset--double">
                <InputRhf<FormValues>
                  hideLabel
                  type="text"
                  name="insuranceProvider"
                  label="Insurance Provider"
                  register={register}
                  placeholder="Insurance Provider"
                  error={errors.insuranceProvider}
                />
                <InputRhf<FormValues>
                  hideLabel
                  type="text"
                  name="insuranceId"
                  label="Insurance ID"
                  register={register}
                  placeholder="Insurance ID"
                  error={errors.insuranceId}
                />
              </fieldset>
              <p className="form__legend">How did you hear about Cyti?</p>
              {isReferralError && referralError ? (
                <p className="home__subText">
                  {referralError.response?.data.message ||
                    referralError.message}
                </p>
              ) : (
                <fieldset className="form__fieldset form__fieldset--double">
                  <Controller
                    name="referralSource"
                    control={control}
                    rules={{ required: { value: true, message: 'Required' } }}
                    render={({ field }) => (
                      <Select
                        id="referralSource"
                        placeholder="Referral Category"
                        {...field}
                        error={errors.referralSource}
                        value={field.value}
                        onValueChange={field.onChange}
                        customValues={referralSources}
                      >
                        {isReferralSuccess &&
                          referralData.data.map((value) => (
                            <SelectItem key={value} value={value}>
                              {value}
                            </SelectItem>
                          ))}
                      </Select>
                    )}
                  />
                  {personReferralCategories.includes(wathcReferralSourse) && (
                    <InputRhf<FormValues>
                      hideLabel
                      type="text"
                      name="referredBy"
                      label="Referred By"
                      register={register}
                      placeholder="Referred By (optional)"
                      error={errors.referredBy}
                    />
                  )}
                </fieldset>
              )}
              {isError && error && (
                <p>{error.response?.data.message || error.message}</p>
              )}
              <div className="form__save">
                <Link to="/" className="form__btn form__btn--stroke">
                  Cancel
                </Link>
                <button
                  type="submit"
                  className="form__btn"
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <Loader color="#ffffff" width="20" height="20" />
                  ) : (
                    'Submit'
                  )}
                </button>
              </div>
            </form>
          )}
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default Match;
