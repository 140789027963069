import React, { ReactNode } from 'react';
import {
  Path,
  FieldError,
  FieldValues,
  RegisterOptions,
  UseFormRegister,
} from 'react-hook-form';

import { Input, InputProps } from './Input';

type InputRhfProps<TFormValues extends FieldValues> = {
  name: Path<TFormValues>;
  label: ReactNode | string;
  rules?: RegisterOptions;
  error?: FieldError;
  hideLabel?: boolean;
  hideError?: boolean;
  register?: UseFormRegister<TFormValues>;
} & Omit<InputProps, 'name'>;

export const InputRhf = <TFormValues extends FieldValues>({
  name,
  label,
  rules,
  error,
  hideLabel = false,
  hideError = false,
  register,
  ...props
}: InputRhfProps<TFormValues>) => {
  const hasError = !!(error && error.message);

  return (
    <label htmlFor={name} className="label label--100w">
      <span className={`label__text ${hideLabel ? 'srOnly' : ''}`}>
        {label}
      </span>
      <Input
        id={name}
        {...props}
        name={name}
        {...(register && register(name, rules))}
        aria-invalid={hasError ? 'true' : 'false'}
        className={`${hasError ? 'input--error' : ''}`}
      />
      {hasError && (
        <span
          role="alert"
          className={`label__error ${hideError ? 'srOnly' : ''}`}
        >
          {error.message}
        </span>
      )}
    </label>
  );
};
