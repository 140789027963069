import React, { ReactNode, forwardRef } from 'react';
import * as DialogPrimitive from '@radix-ui/react-dialog';

export const DialogRoot = DialogPrimitive.Root;
export const DialogTrigger = DialogPrimitive.Trigger;

interface DialogContentProps extends DialogPrimitive.DialogContentProps {
  children: ReactNode;
  title: string;
  mainClassName?: string;
  closeHandler?: () => void;
}

type Ref = HTMLDivElement;

export const DialogContent = forwardRef<Ref, DialogContentProps>(
  (
    { children, title, mainClassName, closeHandler, ...props },
    forwardedRef,
  ) => (
    <DialogPrimitive.Portal>
      <DialogPrimitive.Overlay className="dialog__overlay" />
      <DialogPrimitive.Content
        {...props}
        ref={forwardedRef}
        className="dialog__content"
        aria-describedby={undefined}
        onEscapeKeyDown={() => closeHandler && closeHandler()}
        onCloseAutoFocus={() => closeHandler && closeHandler()}
        onInteractOutside={() => closeHandler && closeHandler()}
        onPointerDownOutside={() => closeHandler && closeHandler()}
      >
        <DialogPrimitive.Title className="dialog__title srOnly">
          {title}
        </DialogPrimitive.Title>
        <div className={`dialog__main scrollbar ${mainClassName || ''}`}>
          {children}
        </div>
      </DialogPrimitive.Content>
    </DialogPrimitive.Portal>
  ),
);

DialogContent.displayName = 'DialogContent';
