export const personReferralCategories = [
  'Attorney',
  'Behavioral Health Hospital',
  'Chiropractor',
  'Clergy',
  'Colleges and Universities',
  'Community Mental Health Agency',
  'Employee Assistance Professional (EAP)',
  'Employer',
  'Health Insurance',
  'Judge',
  'Medical Hospital',
  'Non-Profit Agency',
  'Nurse Practitioner',
  'Physician',
  'Physical Therapy',
  'Psychiatrist',
  'Psychologist',
  'Residential Treatment Center (SUD/MH)',
  'School',
  'School Counselor',
  'Therapist',
];
