import { AxiosError } from 'axios';
import { useMutation } from '@tanstack/react-query';

import { api } from '../../../../services/apiUtils';

import { ApiError } from '../../../../@types/api';

export const useSendReferralEmail = () => {
  return useMutation<
    { status: 'OK' },
    AxiosError<ApiError>,
    {
      body: {
        name: string;
        email: string;
        referredBy: string;
        referralSource: string;
      };
    }
  >({
    mutationFn: async ({ body }) => {
      const { data } = await api.post<{ status: 'OK' }>(
        `/users/referral`,
        JSON.stringify(body),
      );
      return data;
    },
  });
};
