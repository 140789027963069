/* eslint-disable indent */
import React, { FC, useEffect } from 'react';
import dayjs from 'dayjs';

import { FormValues } from './SignupDialogForm';

import { Loader } from '../../../components/Loader';
import { useCreateUser } from '../api/mutations/useCreateUser';
import { useCreateAppointment } from '../api/mutations/useCreateAppointment';
import { useSendReferralEmail } from '../api/mutations/useSendReferralEmail';

import { onImageError } from '../../../utils/image';

import { ProviderSlotType } from '../../../@types/api';
import { ProviderDialogType } from '../../../@types/providerDialog';

type SignupDialogDetailsProps = {
  formValues: FormValues;
  dialogData: ProviderDialogType;
  selectedSlot: ProviderSlotType;
  changeCurrentSlide: (value: 0 | 1 | 2) => void;
};

export const SignupDialogDetails: FC<SignupDialogDetailsProps> = ({
  formValues,
  dialogData,
  selectedSlot,
  changeCurrentSlide,
}) => {
  const {
    isSuccess: isCreateUserSuccess,
    isLoading: isCreateUserLoading,
    isError: isCreateUserError,
    error: createUserError,
    data: createUserData,
    mutate: createUser,
  } = useCreateUser();

  const {
    isSuccess: isCreateAppointmentSuccess,
    isLoading: isCreateAppointmentLoading,
    isError: isCreateAppointmentError,
    error: createAppointmentError,
    data: createAppointmentData,
    mutate: createAppointment,
  } = useCreateAppointment();

  const {
    isSuccess: isSendReferralSuccess,
    isLoading: isSendReferralLoading,
    isError: isSendReferralError,
    error: sendReferralError,
    data: sendReferralData,
    mutate: sendReferral,
  } = useSendReferralEmail();

  const requestSignup = () => {
    createUser({
      body: {
        firstName: formValues.firstName,
        lastName: formValues.lastName,
        birthDate: dayjs(formValues.birthDate).format('YYYY-MM-DD'),
        gender: formValues.gender,
        mobile: formValues.phone,
        email: formValues.email,
      },
    });
  };

  const requestAppointment = (userId: number) => {
    if (selectedSlot) {
      createAppointment({
        body: {
          userId,
          email: formValues.email,
          firstName: formValues.firstName,
          lastName: formValues.lastName,
          birthDate: formValues.birthDate,
          gender: formValues.gender,
          phone: formValues.phone,
          referredBy: formValues.referredBy,
          referralSource: formValues.referralSource,
          providerId: dialogData.provider.id,
          startDate: selectedSlot.startDate,
          endDate: selectedSlot.endDate,
          appointmentId: dialogData.appointmentId,
          assessmentTypeIds: dialogData.assessmentTypeIds,
        },
      });
    }
  };

  const requestReferral = () => {
    sendReferral({
      body: {
        name: `${formValues.firstName} ${formValues.lastName}`,
        email: formValues.email,
        referredBy: formValues.referredBy,
        referralSource: formValues.referralSource,
      },
    });
  };

  useEffect(() => {
    if (isCreateUserSuccess && createUserData.data.userId) {
      requestAppointment(createUserData.data.userId);
      window.dataLayer.push({
        event: 'booking_signup_sumbit',
        state: dialogData.state || 'California',
        insurance: dialogData.insurance || null,
        area_of_focus: dialogData.condition || null,
        appointment_type: dialogData.typeOfSession || null,
        therapist_name: dialogData.provider
          ? `${dialogData.provider.firstName} ${dialogData.provider.lastName}`
          : {},
        therapist_gender: dialogData.provider.gender || {},
        user_bd: dayjs(formValues.birthDate).format('YYYY-MM-DD'),
        user_gender: formValues.gender,
      });
    }
  }, [isCreateUserSuccess, createUserData]);

  useEffect(() => {
    if (isCreateAppointmentSuccess && createAppointmentData.status === 'OK') {
      requestReferral();
    }
  }, [isCreateAppointmentSuccess, createAppointmentData]);

  useEffect(() => {
    if (isSendReferralSuccess && sendReferralData.status === 'OK') {
      changeCurrentSlide(2);
    }
  }, [isSendReferralSuccess, sendReferralData]);

  return (
    <>
      <h2 className="signupDialogDetails__title1">Appointment details</h2>
      {selectedSlot && (
        <div className="signupDialogDetails__cards">
          <div>
            <div className="signupDialogDetails__provider">
              <div className="signupDialogDetails__photo">
                <img
                  src={dialogData.provider.photo}
                  alt={dialogData.provider.firstName}
                  className="signupDialogDetails__img"
                  onError={onImageError}
                />
              </div>
              <div>
                <p className="signupDialogDetails__name">
                  {dialogData.provider.firstName} {dialogData.provider.lastName}
                </p>
                <p className="signupDialogDetails__dates">
                  <span className="signupDialogDetails__date">
                    <svg
                      width="16"
                      height="16"
                      focusable="false"
                      aria-hidden="true"
                    >
                      <use xlinkHref="/icons.svg#calendar" />
                    </svg>
                    {dayjs
                      .utc(selectedSlot.startDate)
                      .tz('PST')
                      .format('MMM DD YYYY')}
                  </span>
                  <span className="signupDialogDetails__date">
                    <svg
                      width="16"
                      height="16"
                      focusable="false"
                      aria-hidden="true"
                    >
                      <use xlinkHref="/icons.svg#clock" />
                    </svg>
                    {dayjs
                      .utc(selectedSlot.startDate)
                      .tz('PST')
                      .format('hh:mmA')}
                    {' - '}
                    {dayjs.utc(selectedSlot.endDate).tz('PST').format('hh:mmA')}
                  </span>
                </p>
              </div>
            </div>
            <div className="signupDialogDetails__card signupDialogDetails__personal">
              <h2 className="signupDialogDetails__title">Personal details</h2>
              <p className="signupDialogDetails__text">
                {formValues.firstName} {formValues.lastName}
              </p>
              <div className="signupDialogDetails__wrapper">
                <div className="signupDialogDetails__label">
                  <p className="signupDialogDetails__subText">Birth Date</p>
                  <p className="signupDialogDetails__text">
                    {formValues.birthDate}
                  </p>
                </div>
                <div className="signupDialogDetails__label">
                  <p className="signupDialogDetails__subText">Gender</p>
                  <p className="signupDialogDetails__text">
                    {formValues.gender}
                  </p>
                </div>
              </div>
            </div>
            <div className="signupDialogDetails__card signupDialogDetails__contact">
              <h2 className="signupDialogDetails__title">Contact details</h2>
              <p className="signupDialogDetails__text">{formValues.phone}</p>
              <p className="signupDialogDetails__text">{formValues.email}</p>
            </div>
          </div>
          <div>
            <div className="signupDialogDetails__card signupDialogDetails__session">
              <h2 className="signupDialogDetails__title">Session details</h2>
              <div className="signupDialogDetails__grid">
                <div className="signupDialogDetails__label">
                  <p className="signupDialogDetails__subText">State</p>
                  <p className="signupDialogDetails__text">
                    {dialogData.state}
                  </p>
                </div>
                <div className="signupDialogDetails__label">
                  <p className="signupDialogDetails__subText">Visit Reason</p>
                  <p className="signupDialogDetails__text">
                    {dialogData.condition}
                  </p>
                </div>
                <div className="signupDialogDetails__label">
                  <p className="signupDialogDetails__subText">Session Type</p>
                  <p className="signupDialogDetails__text">
                    {dialogData.typeOfSession}
                  </p>
                </div>
                <div className="signupDialogDetails__label">
                  <p className="signupDialogDetails__subText">Language</p>
                  <p className="signupDialogDetails__text">
                    {dialogData.language}
                  </p>
                </div>
                <div className="signupDialogDetails__label">
                  <p className="signupDialogDetails__subText">Payment method</p>
                  <p className="signupDialogDetails__text">
                    {dialogData.insurance}
                  </p>
                </div>
                <div className="signupDialogDetails__label">
                  <p className="signupDialogDetails__subText">
                    Therapist gender
                  </p>
                  <p className="signupDialogDetails__text">
                    {dialogData.gender}
                  </p>
                </div>
              </div>
            </div>
            <div className="signupDialogDetails__card signupDialogDetails__hear">
              <h2 className="signupDialogDetails__title">
                How did you hear about Cyti?
              </h2>
              <p className="signupDialogDetails__text">
                {formValues.referralSource}
              </p>
              <p className="signupDialogDetails__text">
                Referred by {formValues.referredBy || '..'}
              </p>
            </div>
          </div>
        </div>
      )}
      {isCreateUserError && createUserError && (
        <p>
          {createUserError.response?.data.message ===
          'A user with this email already exists' ? (
            <span>
              This email is already in use. If you are an existing user, please{' '}
              <a
                href="https://portal.mendfamily.com/signup/new-patient/1932"
                className="font-semibold text-black underline"
              >
                here
              </a>
              .
            </span>
          ) : (
            <span>
              {createUserError.response?.data.message ||
                createUserError.message}
            </span>
          )}
        </p>
      )}
      {isCreateAppointmentError && createAppointmentError && (
        <p>
          {createAppointmentError.response?.data.message ||
            createAppointmentError.message}
        </p>
      )}
      {isSendReferralError && sendReferralError && (
        <p>
          {sendReferralError.response?.data.message ||
            sendReferralError.message}
        </p>
      )}
      <div className="form__save">
        <button
          type="button"
          className="form__btn form__btn--stroke"
          onClick={() => changeCurrentSlide(0)}
          disabled={
            isCreateUserLoading ||
            isCreateAppointmentLoading ||
            isSendReferralLoading
          }
        >
          Cancel
        </button>
        <button
          type="button"
          className="form__btn"
          onClick={() => requestSignup()}
          disabled={
            isCreateUserLoading ||
            isCreateAppointmentLoading ||
            isSendReferralLoading
          }
        >
          {isCreateUserLoading ||
          isCreateAppointmentLoading ||
          isSendReferralLoading ? (
            <Loader color="#ffffff" width="20" height="20" />
          ) : (
            'Confirm'
          )}
        </button>
      </div>
    </>
  );
};
