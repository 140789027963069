import React, { FC } from 'react';

type LoaderProps = {
  color?: string;
  width?: string;
  height?: string;
};

export const Loader: FC<LoaderProps> = ({
  color = '#D6D6D6',
  width = '78',
  height = '78',
}) => {
  return (
    <div
      className="loader"
      style={{ color: `${color}`, width: `${width}px`, height: `${height}px` }}
    >
      <svg width={width} height={height} focusable="false" aria-hidden="true">
        <use xlinkHref="/icons.svg#loader" />
      </svg>
    </div>
  );
};
