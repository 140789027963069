/* eslint-disable indent */
import React, { Dispatch, FC, SetStateAction } from 'react';

import { Select, SelectItem } from '../../../components/Select';

import { useFilters } from '../api/queries/useFilters';
import { useInsurance } from '../api/queries/useInsurance';

export type FiltersProps = {
  state: string;
  setState: Dispatch<SetStateAction<string>>;
  insurance: string;
  setInsurance: Dispatch<SetStateAction<string>>;
  areaOfFocus: string;
  setAreaOfFocus: Dispatch<SetStateAction<string>>;
  ageCategory: string;
  setAgeCategory: Dispatch<SetStateAction<string>>;
  language: string;
  setLanguage: Dispatch<SetStateAction<string>>;
  gender: string;
  setGender: Dispatch<SetStateAction<string>>;
  providersDataLength: number | undefined;
};

export const Filters: FC<FiltersProps> = ({
  state,
  setState,
  insurance,
  setInsurance,
  areaOfFocus,
  setAreaOfFocus,
  ageCategory,
  setAgeCategory,
  language,
  setLanguage,
  gender,
  setGender,
  providersDataLength,
}) => {
  const {
    isSuccess: isFiltersSuccess,
    isError: isFiltersError,
    error: filtersError,
    data: filters,
  } = useFilters();

  const {
    isSuccess: isCurrentInsuranceSuccess,
    isError: isCurrentInsuranceError,
    error: currentInsuranceError,
    data: currentInsurance,
  } = useInsurance({ state });

  const statesOptions =
    (isFiltersSuccess &&
      filters.data.states.map((state) => ({
        label: state.displayName,
        value: state.tag,
      }))) ||
    [];

  const insurancesOptions =
    isCurrentInsuranceSuccess && currentInsurance.data.length && state
      ? currentInsurance.data.reduce<Record<string, string>>((prev, curr) => {
          prev[curr.tag] = curr.displayName;

          return prev;
        }, {})
      : { 'Self Pay': 'Self Pay' };

  const conditionsOptions =
    (isFiltersSuccess &&
      filters.data.conditions.map((condition) => ({
        label: condition.displayName,
        value: condition.tag,
      }))) ||
    [];

  const admissibleMainFiltersOptions =
    (isFiltersSuccess &&
      filters.data.admissibleMainFilters.map((admissibleMainFilter) => ({
        label: admissibleMainFilter.displayName,
        value: admissibleMainFilter.tag,
      }))) ||
    [];

  return (
    <div className="home__filters">
      {isFiltersError && filtersError ? (
        <p>{filtersError.response?.data.message || filtersError.message}</p>
      ) : isCurrentInsuranceError && currentInsuranceError ? (
        <p>
          {currentInsuranceError.response?.data.message ||
            currentInsuranceError.message}
        </p>
      ) : (
        <div className="home__grid">
          <label htmlFor="state" className="label">
            <span
              className={`label__text label__text--absolute ${
                state ? '' : 'label--hiden'
              }`}
            >
              State
            </span>
            <Select
              name="state"
              id="state"
              placeholder="State"
              triggerClassName="select__trigger--filters"
              value={state || undefined}
              onValueChange={(value) => {
                setState(value);
                setInsurance('');
              }}
            >
              {statesOptions.map((option) => (
                <SelectItem key={option.value} value={option.value}>
                  {option.label}
                </SelectItem>
              ))}
            </Select>
          </label>
          <label htmlFor="insurance" className="label">
            <span
              className={`label__text label__text--absolute ${
                insurance ? '' : 'label--hiden'
              }`}
            >
              Payment Method
            </span>
            <Select
              name="insurance"
              id="insurance"
              placeholder="Insurance"
              triggerClassName="select__trigger--filters"
              value={insurance}
              onValueChange={setInsurance}
              customValues={insurancesOptions}
            >
              {Object.entries(insurancesOptions).map((option) => (
                <SelectItem key={option[0]} value={option[0]}>
                  {option[1]}
                </SelectItem>
              ))}
            </Select>
          </label>
          <label htmlFor="ageCategory" className="label">
            <span
              className={`label__text label__text--absolute ${
                ageCategory ? '' : 'label--hiden'
              }`}
            >
              Appointment Type
            </span>
            <Select
              name="ageCategory"
              id="ageCategory"
              placeholder="Type"
              triggerClassName="select__trigger--filters"
              value={ageCategory || undefined}
              onValueChange={setAgeCategory}
            >
              {admissibleMainFiltersOptions.map((option) => (
                <SelectItem key={option.value} value={option.value}>
                  {option.label}
                </SelectItem>
              ))}
            </Select>
          </label>
          <label htmlFor="areaOfFocus" className="label">
            <span
              className={`label__text label__text--absolute ${
                areaOfFocus ? '' : 'label--hiden'
              }`}
            >
              Focus Area
            </span>
            <Select
              name="areaOfFocus"
              id="areaOfFocus"
              placeholder="Focus Area"
              triggerClassName="select__trigger--filters"
              value={areaOfFocus || undefined}
              onValueChange={setAreaOfFocus}
            >
              {conditionsOptions.map((option) => (
                <SelectItem key={option.value} value={option.value}>
                  {option.label}
                </SelectItem>
              ))}
            </Select>
          </label>
          {isFiltersSuccess && filters && state && ageCategory && insurance && (
            <>
              <label htmlFor="language" className="label">
                <span
                  className={`label__text label__text--absolute ${
                    language ? '' : 'label--hiden'
                  }`}
                >
                  Language
                </span>
                <Select
                  name="language"
                  id="language"
                  placeholder="Language"
                  triggerClassName="select__trigger--filters"
                  value={language || undefined}
                  onValueChange={setLanguage}
                >
                  {filters.data.admissibleAdditionalFilters.languages.map(
                    (option) => (
                      <SelectItem key={option.tag} value={option.tag}>
                        {option.displayName}
                      </SelectItem>
                    ),
                  )}
                </Select>
              </label>
              <label htmlFor="gender" className="label">
                <span
                  className={`label__text label__text--absolute ${
                    gender ? '' : 'label--hiden'
                  }`}
                >
                  Gender
                </span>
                <Select
                  name="gender"
                  id="gender"
                  placeholder="Therapist Gender"
                  triggerClassName="select__trigger--filters"
                  value={gender || undefined}
                  onValueChange={setGender}
                >
                  {filters.data.admissibleAdditionalFilters.genders.map(
                    (option) => (
                      <SelectItem key={option} value={option}>
                        {option}
                      </SelectItem>
                    ),
                  )}
                </Select>
              </label>
            </>
          )}
        </div>
      )}
      {providersDataLength !== undefined ? (
        <p className="home__length">Results: {providersDataLength}</p>
      ) : null}
    </div>
  );
};
