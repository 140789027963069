import { AxiosError } from 'axios';
import { useQuery } from '@tanstack/react-query';

import { api } from '../services/apiUtils';

import { ApiError, ApiResult, GetReferralResponse } from '../@types/api';

export const useReferral = () => {
  return useQuery<ApiResult<GetReferralResponse>, AxiosError<ApiError>>({
    queryKey: ['referral'],
    queryFn: async () => {
      const { data } = await api.get<ApiResult<GetReferralResponse>>(
        `/users/referral`,
      );

      return data;
    },
    refetchOnWindowFocus: false,
  });
};
