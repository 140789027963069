import { AxiosError } from 'axios';
import { useMutation } from '@tanstack/react-query';

import { api } from '../../../../services/apiUtils';

import {
  ApiError,
  ApiResult,
  CreateUserResponse,
} from '../../../../@types/api';

export const useCreateUser = () => {
  return useMutation<
    ApiResult<CreateUserResponse>,
    AxiosError<ApiError>,
    {
      body: {
        email: string;
        firstName: string;
        lastName: string;
        birthDate: string;
        gender: string;
        mobile: string;
      };
    }
  >({
    mutationFn: async ({ body }) => {
      const { data } = await api.post<ApiResult<CreateUserResponse>>(
        `/users`,
        JSON.stringify(body),
      );
      return data;
    },
  });
};
