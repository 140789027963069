import React, { FC } from 'react';

export const SignupDialogFinish: FC = () => {
  return (
    <>
      <h2 className="signupDialog__title">
        Your appointment and account have been created
      </h2>
      <p className="signupDialog__text">
        Thank you for scheduling with Cyti Psychological. Please look for the
        following notifications which will be sent to the email provided
      </p>
      <ul className="signupDialog__list">
        <li className="signupDialog__item">
          <span className="signupDialog__marker">1</span>
          You will receive an email with a link to complete your account setup.
          Please verify your email and complete the setup of your account
        </li>
        <li className="signupDialog__item">
          <span className="signupDialog__marker">2</span>
          You will receive a notification of your scheduled time and the name of
          your therapist
        </li>
        <li className="signupDialog__item">
          <span className="signupDialog__marker">3</span>A notification will
          also be sent to complete any forms necessary prior to your visit
        </li>
      </ul>
      <p
        className="signupDialog__text"
        style={{ fontWeight: '500', maxWidth: 'none' }}
      >
        If you need to cancel your session, please do so at least 24 hours prior
        to appointment time.
      </p>
      <div className="form__save">
        <a
          href="https://cyticlinics.com/?utm_source=page&utm_medium=confirmation&utm_campaign=finish&utm_id=Booking"
          target="_blank"
          className="form__btn"
          rel="noreferrer"
        >
          Finish
        </a>
      </div>
    </>
  );
};
