import { AxiosError } from 'axios';
import { useQuery } from '@tanstack/react-query';

import { api } from '../../../../services/apiUtils';

import {
  ApiError,
  ApiResult,
  GetFiltersResponse,
} from '../../../../@types/api';

export const useFilters = () => {
  return useQuery<ApiResult<GetFiltersResponse>, AxiosError<ApiError>>({
    queryKey: ['filters'],
    queryFn: async () => {
      const { data } = await api.get<ApiResult<GetFiltersResponse>>(`/filters`);

      return data;
    },
    refetchOnWindowFocus: false,
  });
};
