import { AxiosError } from 'axios';
import { useQuery } from '@tanstack/react-query';

import { api } from '../../../../services/apiUtils';

import {
  ApiError,
  ApiResult,
  GetInsuranceResponse,
} from '../../../../@types/api';

export const useInsurance = ({ state }: { state: string | undefined }) => {
  return useQuery<ApiResult<GetInsuranceResponse>, AxiosError<ApiError>>({
    queryKey: ['insurance', state],
    queryFn: async () => {
      const { data } = await api.get<ApiResult<GetInsuranceResponse>>(
        `/filters/insurance?state=${state}`,
      );

      return data;
    },
    enabled: !!state,
    refetchOnWindowFocus: false,
  });
};
